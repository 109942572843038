import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";

import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";

import Page from "../components/Layout/Page";
import { FixedWidthContainer } from "../components/Layout/ContentContainers";

import siteConfig from "../../data/SiteConfig";

const debounce = require("debounce");

const QRCode = require("qrcode");

class RegPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      orderNumber: ""
    };

    this.canvasRef = React.createRef();
    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.orderNumberRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  getMetaUrl() {
    return (
      `${siteConfig.siteUrl}${this.props.location.pathname}` ||
      siteConfig.siteUrl
    );
  }

  getMetaTitle() {
    let pageTitle = siteConfig.siteTitle;

    pageTitle = `Fest Reg Barcode Generator`;

    return pageTitle;
  }

  handleInputChange(event) {
    // debounce(this.debouncedHandler(event), 200);

    this.setState({
      [event.target.id]: event.target.value
    });

    QRCode.toCanvas(
      this.canvasRef.current,
      JSON.stringify(this.state),
      error => {
        if (error) console.error(error);
        console.log("success!");
      }
    );
  }

  debouncedHandler(event) {
    this.setState({
      [event.target.id]: event.target.value
    });

    QRCode.toCanvas(
      this.canvasRef.current,
      JSON.stringify(this.state),
      error => {
        if (error) console.error(error);
        console.log("success!");
      }
    );
  }

  render() {
    const { firstName, lastName, orderNumber } = this.state;
    return (
      <Page title="GET YR REG CODE!">
        <Helmet title={this.getMetaTitle()}>
          <meta property="og:url" content={this.getMetaUrl()} />
          <meta property="og:title" content={this.getMetaTitle()} />
        </Helmet>

        <FixedWidthContainer>
          <p>
            Help us speed up registration! Fill this out, turn that brightness
            up, and hand your phone + your PHOTO ID to the reg volunteer!
          </p>
          <Card>
            <CardContent>
              <strong>First name</strong>
              <br />
              <RegInput
                id="firstName"
                label="First Name"
                inputProps={{
                  "aria-label": "Enter your first name"
                }}
                value={firstName}
                onChange={this.handleInputChange}
              />
              <br />
              <br />

              <strong>Last name</strong>
              <br />
              <RegInput
                id="lastName"
                label="Last Name"
                inputProps={{
                  "aria-label": "Enter your last name"
                }}
                value={lastName}
                onChange={this.handleInputChange}
              />
              <br />
              <br />

              <strong>Order number</strong>
              <br />
              <RegInput
                id="orderNumber"
                label="Order Number"
                inputProps={{
                  "aria-label": "Enter your Order Number"
                }}
                value={orderNumber}
                onChange={this.handleInputChange}
              />
              <br />
              <canvas
                id="canvas"
                height="320"
                width="320"
                ref={this.canvasRef}
              />
            </CardContent>
          </Card>
        </FixedWidthContainer>
      </Page>
    );
  }
}

const RegInput = styled.input`
  border-width: 1px;
  border-color: #333;

  padding: 6px;
`;

export default RegPage;
